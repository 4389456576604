import React, { useState, useRef } from 'react';
// import ImageUploading from 'react-image-uploading';
import css from './MultipleImagesUploader.module.css';

export function MultipleImagesUploader(props) {
  const {
    maxNumber,
    afterPhotos = false,
    label,
    underLabel,
    handleUploadPhotos,
    metadataKey,
    image,
    imageUploadLoader,
    imageUploadError,
    onUpdateTransactionPhoto,
    onRemoveImage,
    removeAllImages,
  } = props;

  const handleImageChange = (e, index) => {
    const files = e.target.files;
    if (!files.length) return;
    handleUploadPhotos(files[0]);

    if (typeof window != 'undefined') {
      document.getElementById('file-input-add').value = null;
    }
  };

  return (
    <div className={css.vehiclePhotoUpload}>
      <div className={css.root}>
        <h2 className={css.uploadPhotoLabel}>{label}</h2>
        <p className={css.uploadPhotoUnderlabel}>{underLabel}</p>

        <input
          id="file-input-add"
          type="file"
          accept="image/*"
          capture="camera"
          name="uploadImage"
          className={css.fileInput}
          onChange={e => handleImageChange(e)}
        />
        <label
          htmlFor="file-input-add"
          className={image?.length === maxNumber ? css.labelDisabled : css.label}
        >
          Agregar fotos
        </label>

        {imageUploadLoader ? <div>image upload in progress</div> : null}

        {imageUploadError ? <div>opps failed to upload image</div> : null}

        <div>
          {image.map((image, index) => (
            <div key={index} className={css.imageItem}>
              <img src={image} alt={`Upload Image ${index}`} className={css.image} />

              <div className={css.imageButtonWrapper}>
                <label htmlFor={`file-input-${index}`} className={css.label}>
                  Actualizar
                </label>
                <button className={css.button} onClick={() => onRemoveImage(index)}>
                  Remover
                </button>
              </div>
            </div>
          ))}
        </div>
        {image.length > 0 && (
          <button onClick={removeAllImages} className={css.button}>
            Remover todas
          </button>
        )}
      </div>

      {image.length > 0 ? (
        <div className={css.uploadToStWrapper}>
          <button
            className={
              image.length < maxNumber || imageUploadLoader
                ? css.buttonLongDisabled
                : css.buttonLong
            }
            onClick={onUpdateTransactionPhoto}
            loading={imageUploadLoader}
            disabled={imageUploadLoader}
          >
            {imageUploadLoader ? 'Cargando...' : 'Guardar fotos'}
          </button>
        </div>
      ) : null}
    </div>
  );
}
